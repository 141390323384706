import React from "react";
import Header from "../../Shared/Header";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import doImage from "../../../Assets/Images/doImg.svg";
import dontImage from "../../../Assets/Images/dontImg.svg";
import dos from "../../../Assets/Images/dos.svg";
import donts from "../../../Assets/Images/don't.svg";

import { APP_ROUTES } from "../../PageRouting/AppRoutes";

export default function SmartVitalsInstruct() {
    const history = useHistory();
    const routetoChecker = async () => {
        history.push(APP_ROUTES.SMARTVITALS_CHECKER)
    }

    return (
        <>
            <Header /> {/* Header component will render at the top */}
            {/* Main content area */}
            <div className="flex flex-col items-center w-full mt-20 px-4">
                {/* Facial Key Banner */}
                <div className="bg-primaryColor text-white py-4 px-6 rounded-lg shadow-md w-full mb-8 mt-8">
                    <p className="font-semibold text-lg text-White">
                        Create Facial Key:
                        <span className="font-normal ml-2 text-base text-White">
                            Create a map of your face. You can delete the map at any time.
                        </span>
                    </p>
                </div>

                {/* Instructions Section */}
                <div className="bg-white shadow-md rounded-lg p-6 w-full mb-8">
                    {/* "Do" Instruction */}
                    <div className="flex items-start mb-4">
                        <img
                            src={doImage}
                            alt="Do"
                            className="w-20 h-20 mr-4" // Increased image size here
                        />
                        <div>
                            <div className="flex items-center mb-2">
                                <img src={dos} alt="Do Icon" className="w-14 h-5 mr-2" />
                            </div>
                            <p className="text-gray-700 text-sm">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy
                                text ever since the 1500s.
                            </p>
                        </div>
                    </div>

                    {/* "Don't" Instruction */}
                    <div className="flex items-start">
                        <img
                            src={donts}
                            alt="Don't"
                            className="w-20 h-20 mr-4" // Increased image size here
                        />
                        <div>
                            <div className="flex items-center mb-2">
                                <img src={dontImage} alt="Don't Icon" className="w-14 h-5 mr-2" />
                            </div>
                            <p className="text-gray-700 text-sm">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy
                                text ever since the 1500s.
                            </p>
                        </div>
                    </div>

                    {/* Get Started Button */}
                    <div className="flex justify-center mt-6">
                        <button
                            className="bg-primaryColor text-white py-2 px-6 rounded-lg shadow-md text-sm font-semibold text-White"
                            onClick={routetoChecker}
                        >
                            Get Started
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
