import pills from "../../../Assets/Images/Cart/Medicines.jpg";
import RXicon from "../../../Assets/Images/Cart/rxicon.png";
import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const MedicineList = (props) => {
  const {
    patientMedicineOrder,
    deleteMedicine,
    quantityUpdate,
    readOnly = false,
    loading,
    debouncedQuantityUpdate,
  } = props;

  if (!patientMedicineOrder.length) {
    return;
  }

  const calculateMRP = (res) => {
    if (res?.totalAmount) {
      return res?.totalAmount.toFixed(2);
    } else {
      return parseFloat(res?.unitPrice).toFixed(2);
    }
  };

  const calculateDiscountAmount = (res) => {
    return parseFloat(res?.unitPrice * res?.quantity).toFixed(2);
  };

  const calculateDiscountPrecentage = (data) => {
    let totalAmount = parseFloat(data?.unitPrice * data?.quantity).toFixed(2);
    let discountAmount = data?.totalAmount.toFixed(2);

    let precentage = Math.round(100 - (discountAmount / totalAmount) * 100);

    return precentage;
  };

  return (
    <div className=" bg-White mt-5">
       {loading && <p className="text-White"> Loading...</p>}
      {patientMedicineOrder[0].patientMedicineOrderDetailsList.map((res, i) =>
        res.status == 1 ? (
          <div className="bg-lightBlue items-center flex mb-3 last:mb-0 rounded-md p-4 lg:w-full">
            <div className="md:flex gap-3 w-full">
              <div className="h-20 w-20">
                <img
                  src={pills}
                  alt="tablet"
                  className="w-full h-full object-fit"
                />
              </div>
              <div className="w-full">
                <div className="flex justify-between items-baseline">
                  <div>
                    <div className="flex gap-1 items-center">
                      {res.prescriptionRequired == "Y" ? (
                        <img src={RXicon} className=" h-4 " />
                      ) : null}
                      <p className="text-md text-primaryColor font-medium">
                        {res.drugName}
                      </p>
                    </div>
                    <div className="text-xs text-textGray capitalize">
                      {res?.medicineTypeOfSell}
                    </div>
                  </div>
                  {!readOnly ? (
                    <div className="flex mt-1 justify-end lg:justify-center flex-col items-center">
                      {/* <DeleteIcon
                      style={{ color: "#DD1A1A" }}
                      className="cursor-pointer"
                      onClick={(e) => {
                        deleteMedicine(e, res, {
                          medicineId: res.id,
                        });
                      }}
                    /> */}
                      <DeleteOutlined
                        onClick={(e) => {
                          deleteMedicine(e, res, {
                            medicineId: res.id,
                          });
                        }}
                        className="text-danger text-lg cursor-pointer" />
                    </div>
                  ) : null}
                </div>
                <div className="flex justify-between items-center mt-2">
                  <div className=" md:flex items-baseline md:mt-3 ">
                    <p className="text-primaryColor font-semibold text-base">
                      ₹ {calculateMRP(res)}
                    </p>
                    {res?.discountAmount ? (
                      <>
                        <p className="text-xs line-through text-textGray  md:pl-2">
                          ₹ {calculateDiscountAmount(res)}
                        </p>
                        <p
                          className="text-xs font-medium ml-1"
                          style={{ color: "#DD1A1A" }}
                        >
                          ({calculateDiscountPrecentage(res)}%off)
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex items-center justify-center w-[100px] h-[30px] ">
                    <button
                      onClick={(e) => {
                        const quantity = res.quantity;
                        if (quantity > 1) {
                          debouncedQuantityUpdate(e, res, quantity - 1, i);
                        } else {
                          deleteMedicine(e, res, {
                            medicineId: res.id,
                          });
                        }
                      }}
                      disabled={loading} 
                      className="w-[35%] h-full text-sm font-light border border-primaryColor text-primaryColor rounded-s-md"
                    >
                      <MinusOutlined />
                    </button>
                    <div className="w-[50%] flex justify-center items-center h-full border-x-0 border-y border-primaryColor text-base text-primaryColor font-medium">
                      {res.quantity.toString().padStart(2, "0")}
                    </div>
                    <button
                      onClick={(e) => {
                        const quantity = res.quantity;
                        debouncedQuantityUpdate(e, res, quantity + 1, i);
                      }}
                      disabled={loading} 
                      className="w-[35%] h-full text-sm font-light border border-primaryColor text-primaryColor rounded-e-md"
                    >
                      <PlusOutlined />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};

export default MedicineList;
