// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";
// import { APP_ROUTES } from "../../PageRouting/AppRoutes";

// function VideoCall() {
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const [url, setUrl] = useState("");
//   const location = useLocation();
//   const history = useHistory();
//   const { state } = location;
//   const queryParams = new URLSearchParams(location.search);
//   const joinCode = queryParams.get('joinCode') || localStorage.getItem('joinCode');
//   const consultationType = queryParams.get('consultationType') || localStorage.getItem('consultationType');

//   const getLink = () => {
//     const header = {
//       secretKey: process.env.REACT_APP_MAGGIEPLUS_SECRET_KEY,
//     };
//     const defaultEmail = "patient@gmail.com"
//     const data = {
//       clientCode: "CCM@@202!",
//       userType: "HOST",
//       meetingKey: joinCode ? joinCode : state.id,
//       memberName: userData.firstName,
//       memberEmail: userData.email || defaultEmail,
//       memberMobile: userData.mobile,
//     };
//     axios
//       .post(`${process.env.REACT_APP_MAGGIEPLUS}`, data, { headers: header })
//       .then((result) => {
//         if (state?.consultationsType == "A") {
//           setUrl(result.data.response.url + "/1");
//         } else {
//           setUrl(result.data.response.url);
//         }

//         setTimeout(() => {
//           document.getElementById("videoframe").style.display = "none";
//           document.getElementById("videoload").style.display = "block";
//         }, 100);
//         setTimeout(() => {
//           document.getElementById("videoframe").style.display = "block";
//           document.getElementById("videoload").style.display = "none";
//         }, 6000);
//       })
//       .catch((err) => {
//         console.log("Err", err);
//       });
//   };

//   useEffect(() => {
//     if (!userData.code && joinCode) {
//       history.push({
//         pathname: APP_ROUTES.HOME,
       
//       });
//     }
//   }, [userData, joinCode]);

//   useEffect(() => {
//     if (!state?.id) {
//       console.log("Waiting for state.id...");
//       return; // Exit if state?.id is not yet available
//     }
//     getLink();

//   }, [state?.id]);

//   useEffect(() => {
//     if (userData.code && joinCode) { 
//       getLink();
//     }
//   }, [userData, joinCode]);
  

//   if (!url) {
//     return <p>Loading...</p>;
//   }

//   const endconsultation = () => {
//     history.push(APP_ROUTES.HOME);
//   };

//   return (
//     <>
//       <div className="p-3 md:p-10 flex-col">
//         <div className="flex space-x-4 flex-col">
//           <div className="w-full">
//             <div className="relative">
//               <div
//                 id="videoload"
//                 style={{
//                   width: "100px",
//                   height: "100px",
//                   margin: "auto",
//                   textAlign: "center",
//                   display: "none",
//                 }}
//               >
//                 Loading .....
//               </div>
//               <div id="videoframe">
//                 <div
//                   dangerouslySetInnerHTML={{
//                     __html: `<iframe class="myframe"  allow="camera; microphone;" sandbox = "allow-scripts allow-same-origin allow-modals allow-top-navigation" style="width: 100%; height:500px" src=${url} frameborder="0" allowfullscreen showControls ></iframe>`,
//                   }}
//                 ></div>
//               </div>
//             </div>
//           </div>

//           <div className="self-center mt-8 text-primaryColor">
//             {`To view and download your prescription please go to My Profile-->Click on My Health Records-->Click on My Prescription`}
//           </div>

//           <div className="w-full bottom-0 flex justify-center mt-5">
//             <button
//               onClick={endconsultation}
//               className="bg-secondaryColor  text-sm text-White font-normal rounded-md py-2 px-3 mr-2"
//             >
//               {url && ` End Consultation`}
//             </button>
//             <br />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// export default VideoCall;


// import React, { useEffect, useRef, useState } from "react";
// import AgoraRTC from "agora-rtc-sdk-ng";
// import axios from "axios";
// import { useHistory, useLocation } from "react-router";
// import micIcon from "../../../Assets/Images/mic_icon.svg";
// import videoCallIcon from "../../../Assets/Images/video_call.svg";
// import endCallIcon from "../../../Assets/Images/end_call.svg";
// import otherUser from "../../../Assets/Images/other_user.svg";
// import micOffIcon from "../../../Assets/Images/mic_off_icon.svg";
// import cameraIcon from "../../../Assets/Images/camera_icon.svg";
// import { APP_ROUTES } from "../../PageRouting/AppRoutes";
// import OtherUserVideo from "./OtherUserVideo";

// const appId = "5da64dc39db9467485eeaac6d515f210";

// function VideoCall(props) {
//     const [videoCall, setVideoCall] = useState(false);
//     const [isJoined, setIsJoined] = useState(false);
//     const [isMuted, setIsMuted] = useState(false);
//     const [isCameraOff, setIsCameraOff] = useState(false);
//     const [remoteUsers, setRemoteUsers] = useState([]); // Track remote users with camera status

//     const client = useRef(null);
//     const localAudioTrack = useRef(null);
//     const localVideoTrack = useRef(null);
//     const localPlayerRef = useRef(null);
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const joinCode = queryParams.get('joinCode') || sessionStorage.getItem('joinCode');
//     const history = useHistory();
//     const { state } = location;

//     const setupAgora = async () => {
//         try {
//             client.current = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
//             // console.log(joinCode, state?.id,"dsvdsovhsdouhvdsvdsv")
//             const channelId = joinCode ? String(joinCode) : (state?.id ? String(state.id) : "defaultChannel");
//             const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");

//             const response = await axios.get(
//                 `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
//             );
//             const token = response.data?.data?.token;
//             const uuid = response.data?.data?.uid;
//             if (!token || !appId || !sanitizedChannelId) {
//                 throw new Error("App ID, Token, or Channel ID is undefined");
//             }

//             await client.current.join(appId, sanitizedChannelId, token, uuid);
//             setVideoCall(true);
//             sessionStorage.removeItem('joinCode')
//             sessionStorage.removeItem('consultationType')
//             // Create local audio and video tracks
//             localAudioTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
//             localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();

//             // Play local video track
//             playLocalVideo();

//             // Publish both audio and video tracks to the channel
//             await client.current.publish([localAudioTrack.current, localVideoTrack.current]);
//             console.log("Published local video and audio tracks to the channel");

//             setIsJoined(true);

//             // Handle existing remote users in the channel
//             client.current.remoteUsers.forEach((user) => {
//                 handleExistingUser(user);
//             });

//             // Event listeners for when new users join or leave the channel
//             client.current.on("user-published", handleUserPublished);
//             client.current.on("user-unpublished", handleUserUnpublished);
//         } catch (error) {
//             console.error("Error setting up Agora:", error);
//         }
//     };

//     useEffect(() => {
//       // For now, only setup Agora without Maggie Plus
    
//     }, []);
//     console.log(localVideoTrack?.current, "localVideoTrackcurrent")
//     const playLocalVideo = () => {
//         if (localPlayerRef.current) {
//             // Clear any previous video feed
//             localPlayerRef.current.innerHTML = "";
//             if (localVideoTrack.current) {
//                 localVideoTrack.current.play(localPlayerRef.current);
//             }
//         }
//     };

//     const handleExistingUser = async (user) => {
//         await client.current.subscribe(user, "video").catch((e) => console.error(e));
//         await client.current.subscribe(user, "audio").catch((e) => console.error(e));
//         console.log("ExistingUser:" , user)
//         setRemoteUsers((prevUsers) => {
//             const existingUser = prevUsers.find((u) => u.uid === user.uid);
//             if (!existingUser) {
//                 return [...prevUsers, { ...user, isCameraOff: !user.hasVideo }];
//             }
//             return prevUsers;
//         });

//         if (user.hasVideo) {
//             user.videoTrack.play(`remote-player-${user.uid}`);
//         }

//         if (user.hasAudio) {
//             user.audioTrack.play();
//         }
//     };

//     const handleUserPublished = async (user, mediaType) => {
//         await client.current.subscribe(user, mediaType);
//       console.log("UserPublished:", user.videoTrack,mediaType)
//         setRemoteUsers((prevUsers) => {
//             const existingUser = prevUsers.find((u) => u.uid === user.uid);
//             if (!existingUser) {
//                 return [...prevUsers, { ...user, isCameraOff: mediaType !== "video" }];
//             }
//             return prevUsers.map((u) =>
//                 u.uid === user.uid ? { ...u, isCameraOff: mediaType !== "video" } : u
//             );
//         });

//         if (mediaType === "video") {
//             user.videoTrack.play(`remote-player-${user.uid}`);
//         }

//         if (mediaType === "audio") {
//             user.audioTrack.play();
//         }
//     };

//     const handleUserUnpublished = (user, mediaType) => {
//         if (mediaType === "video") {
//             setRemoteUsers((prevUsers) =>
//                 prevUsers.map((u) =>
//                     u.uid === user.uid ? { ...u, isCameraOff: true } : u
//                 )
//             );
//         }
//     };

//     const endconsultation = async () => {
//         if (localAudioTrack.current) localAudioTrack.current.close();
//         if (localVideoTrack.current) {
//             localVideoTrack.current.stop();
//             localVideoTrack.current.close();
//         }
//         await client.current.leave();
//         setIsJoined(false);
//         setVideoCall(false);
//         history.push(APP_ROUTES.HOME);
//     };

//     const toggleMute = async () => {
//         if (localAudioTrack.current) {
//             await localAudioTrack.current.setEnabled(!isMuted);
//             setIsMuted(!isMuted);
//         }
//     };

//     const toggleCamera = async () => {
//         try {
//             if (isCameraOff) {
//                 if (!localVideoTrack.current) {
//                     localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();
//                 }

//                 // Delay playing local video to ensure everything is set up
//                 setTimeout(() => {
//                     playLocalVideo();
//                 }, 200);  // Minor delay to allow DOM updates

//                 await client.current.publish([localVideoTrack.current]);
//                 console.log("Republished local video track");
//             } else {
//                 if (localVideoTrack.current) {
//                     await client.current.unpublish([localVideoTrack.current]);
//                     localVideoTrack.current.stop();
//                     localVideoTrack.current.close();
//                     localVideoTrack.current = null;
//                     console.log("Unpublished and closed local video track");
//                 }
//             }
//             setIsCameraOff(!isCameraOff);
//         } catch (error) {
//             console.error("Error toggling camera:", error);
//         }
//     };

//     useEffect(() => {
//         setupAgora();

//         // return () => {
//         //     endconsultation();
//         // };
//     }, []);

//     const getGridClass = () => {
//         const totalUsers = remoteUsers.length + 1; // Local user + remote users
//         if (totalUsers === 1) return "grid-cols-1";
//         if (totalUsers === 2) return "grid-rows-2 md:grid-rows-1 md:grid-cols-2";
//         if (totalUsers <= 4) return "grid-cols-2 grid-rows-2";
//         return "grid-cols-3 grid-rows-2";
//     };

//     return (
//       <div className="flex flex-col h-screen bg-gray-100">
//           <div className={`grid ${getGridClass()} gap-2 flex-1`}>
//               {/* Local Video */}
//               <div className="relative bg-black">
//                   {isCameraOff ? (
//                       <div className="flex items-center justify-center w-full h-full bg-gray-800">
//                           <img src={otherUser} alt="User Placeholder" className="w-20 h-20 opacity-50" />
//                       </div>
//                   ) : (
//                       <div ref={localPlayerRef} id="local-player" className="w-full h-full" />
//                   )}
//               </div>

//               {/* Remote Videos */}
//               {remoteUsers.map((user) => (
//                   <div key={user.uid} className="relative bg-black w-full h-full">
//                       {user.isCameraOff ? (
//                           <div className="flex items-center justify-center w-full h-full bg-gray-800">
//                               <img src={otherUser} alt="User Placeholder" className="w-20 h-20 opacity-50" />
//                           </div>
//                       ) : (
//                        <OtherUserVideo key={user.uid} user={user} />
//                           // <div key={user.uid}  id={`remote-player-${user.uid}`} className="w-full h-full" />
//                       )}
//                   </div>
//               ))}
//           </div>

//           {/* Controls */}
//           <div className="flex justify-center bg-black bg-opacity-75 p-4">
//               <button onClick={toggleMute} className="text-white bg-white rounded-full p-3">
//                   {isMuted ? <img src={micIcon} alt="Mic Icon" className="w-20 h-20" /> :  <img src={micOffIcon} alt="Mic Icon" className="w-8 h-8" />}
//               </button>
//               <button onClick={toggleCamera} className="text-white bg-white rounded-full p-3 mx-4">
//                 {isCameraOff ? <img src={cameraIcon} alt="Camera Icon" className="w-8 h-8" /> : <img src={videoCallIcon} alt="Camera Icon" className="w-20 h-20" />}
                  
//               </button>
//               <button onClick={endconsultation} className="text-white bg-red-500 rounded-full p-3">
//                   <img src={endCallIcon} alt="End Call Icon" className="w-8 h-8" />
//               </button>
//           </div>

//           {/* Checkbox and Close Button */}
//           <div className="flex flex-col items-center mt-4 mb-4">
//               <button
//                   onClick={endconsultation}
//                   className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mt-4"
//               >
//                   Close
//               </button>
//           </div>
//       </div>
//   );
// }

// export default VideoCall;

import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import micIcon from "../../../Assets/Images/mic_icon.svg";
import videoCallIcon from "../../../Assets/Images/video_call.svg";
import endCallIcon from "../../../Assets/Images/end_call.svg";
import otherUser from "../../../Assets/Images/other_user.svg";
import micOffIcon from "../../../Assets/Images/mic_off_icon.svg";
import cameraIcon from "../../../Assets/Images/camera_icon.svg";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import OtherUserVideo from "./OtherUserVideo";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import "./styles.css";
// import logo from "./agora-logo.svg";

export const VideoCall = () => {
  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();
  // const [appId, setAppId] = useState(""); 
  const [channel, setChannel] = useState(""); 
  const [token, setToken] = useState("");
  const [uId, setUId] = useState("")
      const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const joinCode = queryParams.get('joinCode') || sessionStorage.getItem('joinCode');
    const history = useHistory();
    const { state } = location;
const appId = "5da64dc39db9467485eeaac6d515f210";
  useJoin({appid: appId, channel: channel, token: token ? token : null, uid: uId} , calling);
  //local user
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);
  //remote users
  const remoteUsers = useRemoteUsers();

      const setupAgora = async () => {
        try {
            const channelId = joinCode ? String(joinCode) : (state?.id ? String(state.id) : "defaultChannel");
            const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");

            const response = await axios.get(
                `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
            );
            const token = response.data?.data?.token;
            const uuid = response.data?.data?.uid;
            if (!token || !appId || !sanitizedChannelId) {
                throw new Error("App ID, Token, or Channel ID is undefined");
            }
            sessionStorage.removeItem('joinCode')
            sessionStorage.removeItem('consultationType')
            setToken(token)
            setChannel(channelId)
            setUId(uuid)
        } catch (error) {
            console.error("Error setting up Agora:", error);
        }
    };

    useEffect(() =>{
      if(token && channel && uId){
        setCalling(true)
      }
    },[token, channel, uId])

    useEffect(() => {
      setupAgora();
    },[])

        const getGridClass = () => {
        const totalUsers = remoteUsers.length + 1; // Local user + remote users
        if (totalUsers === 1) return "grid-cols-1";
        if (totalUsers === 2) return "grid-cols-1 md:grid-cols-2";
        if (totalUsers >= 3) return "grid-cols-1 md:grid-cols-2";
        return "grid-cols-3 grid-rows-2";
    };

    console.log(remoteUsers , "remoteUsers" , isConnected ,channel , calling)

        const endconsultation =  () => {
          setCalling(a => !a)
        history.push(APP_ROUTES.HOME);
    };

  return (
    <>
      <div className="room">
        {isConnected ? (
          <div className={`grid ${getGridClass()} gap-1 flex-1`} style={{height: "85vh"}} >
            <div className="user">
              <LocalUser
                audioTrack={localMicrophoneTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                videoTrack={localCameraTrack}
                cover={otherUser}
              >
                <samp className="user-name">You</samp>
              </LocalUser>
            </div>
            {remoteUsers.map((user) => (
              <div className="user" key={user.uid}>
                <RemoteUser cover= {cameraIcon} user={user}>
                  <samp className="user-name">{user.uid}</samp>
                </RemoteUser>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-screen flex justify-center items-center">
           <Spin indicator={<LoadingOutlined spin />} size="large" />
          </div>
        )}
      </div>
      {isConnected && (
        <div className="control">
          <div className="left-control">
            <button className="btn" onClick={() => setMic(a => !a)}>
              <i className={`i-microphone ${!micOn ? "off" : ""}`} />
            </button>
            <button className="btn" onClick={() => setCamera(a => !a)}>
              <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
            </button>
          </div>
          <button
            className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
            onClick={() => endconsultation() }
          >
            {calling ?  <i className="i-phone-hangup" /> : <i className="i-mdi-phone" />}
          </button>
        </div>
      )}
    </>
  );
};

export default VideoCall;


