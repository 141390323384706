import './App.css';
import PageRouting from './Components/PageRouting';
import { Provider } from 'react-redux';
import store from '../src/Redux/store/store';
import Consent from './Components/Consent';
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
function App() {
  return (
    <AgoraRTCProvider client={client}>
    <Provider store={store}>
      <Consent />
      <PageRouting />
    </Provider>
      </AgoraRTCProvider>
  );
}

export default App;
