import { useEffect, useRef } from 'react';

function OtherUserVideo({ user }) {
    const videoRef = useRef(null);

    useEffect(() => {
    console.log(user , videoRef?.current, "dsvjsdgivsdvudsgvudsvsd")
        if (user._videoTrack && videoRef.current) {
            user._videoTrack.play(videoRef.current);
        }
    }, [user._videoTrack]);


    return <div ref={videoRef} className="w-full h-full" />;
}


export default OtherUserVideo