import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import diagnostics from "../../../Assets/Images/diagnostics.png";
import { useLocation, useHistory } from "react-router-dom";
import http from "../../../Redux/Services/http-common";
import axios from "axios";
import Search from "antd/es/input/Search";
import { Card, message } from "antd";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import TestCapsule from "../../../Assets/Images/testTube.svg";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Shared/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trackAddToCartEvent } from "../../utils/analytics/events";

function DiagnosticDetails(props) {
  const location = useLocation();
  const { cartList } = useSelector((state) => state.cartReducer);
  const userData = useSelector((state) => state.authReducer.patientData);
  const currentPath = window.location.pathname;
  const parts = currentPath.split("/");
  const labId = parts.pop(); // Get the last part (e.g., 'MobiLab--LVwRezbdM120924')
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [labSearched, setlabSearched] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [labNewName, setLabNewName] = useState("");
  const [labDetail, setLabDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const drugIDsInCart = getProductsIDs();
  const [cartaddedlab, setcartaddedlab] = useState();

  const componentDidMount = () => {
    const { callbackName } = this.props.location.state;
    if (callbackName) {
      const callbackFunction = this[callbackName];
      if (typeof callbackFunction === "function") {
        callbackFunction();
      }
    }
  };

  useEffect(() => {
    getLabDetailsOnLabId();
  }, []);

  useEffect(() => {
    if (labDetail?.locationId) {
      fetchFewLabTest();
    }
  }, [labDetail]);

  const fetchFewLabTest = async () => {
    setIsOpen(true);
    let res2 = await http.get(
      // `https://patient.curebay.com/es/api/v1/labtest?latitude=${labDetail.loc.lat}&longitude=${labDetail.loc.lon}&freeTextSearch=${e.target.value}&source=b2c`
      `${process.env.REACT_APP_ELASTIC_BASEURL}labs/${labDetail.locationId}/labtest?pageNo=1&pageSize=20`
    );
    console.log(res2.data);

    setlabSearched(res2.data);
  };

  console.log(labDetail);
  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const getLabDetailsOnLabId = async () => {
    http
      .get(`${process.env.REACT_APP_ELASTIC_BASEURL}getLabDetail/${labId}`)
      .then((res) => {
        setLabDetails(res.data[0]);
      });
  };

  const seletedSearch = (event) => {
    // setlabSearched([])
    console.log(event);
    let x = [];
    x.push(event);
    setlabSearched(x);
    setIsOpen(false);
  };
  const handleChange = async (e) => {
    console.log(labDetail);
    setSearchTerm(e.target.value);
    setIsOpen(true);
    let res2 = await axios.get(
      // `https://patient.curebay.com/es/api/v1/labtest?latitude=${labDetail.loc.lat}&longitude=${labDetail.loc.lon}&freeTextSearch=${e.target.value}&source=b2c`
      `${process.env.REACT_APP_ELASTIC_BASEURL}labs/${labDetail.locationId}/labtest?pageNo=1&pageSize=20&freeTextSearch=${e.target.value}`
    );
    console.log(res2.data);
    setlabSearched(res2.data);
  };
  useEffect(() => {
    scrollToTop();
    // getLabDetailsOnLabId();
  }, []);

  const addTocart = (e, data, index, quantity = 1) => {
    console.log(e, data, index, quantity, "labdetails");
    setcartaddedlab(data.labTestCode);
    if (!userData.code) {
      history.push({
        pathname: APP_ROUTES.HOME,
        // state: { background: location, login: true },
      });
    } else {
      // toast("we are currently in the process of upgrading our website, apologies for the inconvenience");
    }

    if (cartList?.patientLabTestsOrder) {
      let availableItem = cartList?.patientLabTestsOrder.find(
        (item) => item.hospitalId === data.labId
      );

      if (availableItem === undefined) {
        // setDialog(true);
        setLabNewName(
          cartList?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]
            ?.hospitalName
        );
        message.warning(
          "Select this test from the same lab or replace the tests in your cart"
        );
        return;
      }
    }

    // setIsAdding(index);
    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (data?.medicinePrescriptionRequired == "N") {
      prescriptionRequired = "N";
      ePrescriptionRequired = "N";
    } else if (data?.medicinePrescriptionRequired == "Y") {
      prescriptionRequired = "Y";
      ePrescriptionRequired = "Y";
    }
    let calculatedAmount = data?.amount;
    const totalAmount = calculatedAmount * quantity;

    let dataObj = {};
    if (cartList && cartList.patientLabTestsOrder) {
      cartList.totalAmount = cartList?.totalAmount
        ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
        : parseFloat(totalAmount);
      cartList.patientLabTestsOrder.map((res) => {
        res.patientLabTestsOrderDetailsList.push({
          patientLabTestOrderId: data.id,
          labTestCode: data.labTestCode,
          labTestName: data.labTestName,
          hospitalId: data.labId,
          locationId: data.locationId,
          amount: data.amount,
          discountAmount: data?.discountAmount ? data?.discountAmount : 0,
          totalAmount: totalAmount,
          status: 1,
          labTestType: data.testType,

          quantity: quantity,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          prescriptionRequired: prescriptionRequired,
          ePrescriptionRequired: ePrescriptionRequired,
          medicineTypeOfSell: null,
          membershipCode: null,
          membershipName: null,
          membershipCard: null,
          membershipDiscountPercentage: 0.0,
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,

        labOrdersYN: true,
        drugsOrdersYN: false,
        totalAmount: cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount),
        patientLabTestsOrder: [
          {
            hospitalId: data.labId,
            locationId: data.locationId,

            orderId: "",
            patientId: userData.code,
            orderDetailsRequired: "Y",
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            amount: totalAmount,
            address1: data.address1,
            address2: data.address2,
            address3: null,
            city: data.city,
            state: data.state,
            country: null,
            pincode: data.pinCode,
            deliveryAddress1: data.address1,
            deliveryAddress2: data.address2,
            deliveryAddress3: null,
            deliveryCity: data.city,
            deliveryState: data.state,
            deliveryCountry: null,
            deliveryZipcode: data.pinCode,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            patientLabTestsOrderDetailsList: [
              {
                patientLabTestOrderId: data.id,
                labTestCode: data.labTestCode,
                labTestName: data.labTestName,
                hospitalId: data.labId,
                locationId: data.locationId,
                amount: data.amount,
                discountAmount: data?.discountAmount ? data?.discountAmount : 0,
                totalAmount: totalAmount,
                status: 1,
                labTestType: data.testType,
                tat: data?.tat,
                labStartTime: data?.labStartTime,
                labEndTime: data?.labEndTime,
                description: data?.description,

                quantity: quantity,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                orderId: null,
                patientId: userData.code,
                prescriptionRequired: prescriptionRequired,
                ePrescriptionRequired: ePrescriptionRequired,
                medicineTypeOfSell: null,
                membershipCode: null,
                membershipName: null,
                membershipCard: null,
                membershipDiscountPercentage: 0.0,
              },
            ],
          },
        ],
        // };
      };
    }
    setLoading(true);
  //   dispatch(AddtoCart(dataObj)).then((res) => {
  //  trackAddToCartEvent(data.amount, data.labTestName);
  //     dispatch(getCartDetails(userData.code));

  let payload = {
       
        
    patientId:userData.code,
    labTestCode:data.labTestCode,
    amount:data.amount,
    hospitalId:data.labId,
    locationId:data.locationId,
    labTestName:data.labTestName,
    labTestType:data.testType,
    sourceChannel:"D2C",

};


fetch(`${process.env.REACT_APP_BASEURL}cart/addToCart/labtest`, {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
  },
  body: JSON.stringify(payload),
})
.then(response => response.json())
.then((res) => {
console.log(res,"vghsdvhcvdsg")
trackAddToCartEvent(data.amount, data.labTestName);
 dispatch(getCartDetails(userData.code));
  setLoading(false);
})
.catch((err) => {
  console.error("Error adding to cart:", err);
 
});
     
      // setIsAdding(-1);
    // });
  };

  function getProductsIDs() {
    let ids = [];
    if (cartList) {
      if (cartList.patientLabTestsOrder) {
        for (const product of cartList.patientLabTestsOrder) {
          // if (product && product.patientLabTestsOrderDetailsList) {
          for (const drug of product.patientLabTestsOrderDetailsList) {
            ids.push(drug.labTestCode);
          }
          // }
        }
      }
    }
    return ids;
  }

  const handleNavigation = (e, data, labTestCode) => {
    console.log(e, data, "fff");
    e.preventDefault();
    history.push({
      pathname: APP_ROUTES.LABVIEW,
      state: { labdata: data, labTestCode: labTestCode },
      login: false,
    });
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <Layouts>
      {loading && <Loader />}
      <ToastContainer />

      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="md:w-full bg-primaryColor rounded-md md:px-8 px-6 py-3 md:py-5">
            <div className="font-medium md:text-2xl text-sm text-White">
              Diagnostics
            </div>
          </div>

          <div className="md:flex gap-8 w-full mt-5">
            <div className="md:w-[40%] h-[180px] md:h-[250px] mb-5 md:mb-0">
              <img
                src={
                  labDetail?.logo
                    ? process.env.REACT_APP_IMG_BASEURL + labDetail.logo
                    : diagnostics
                }
                alt="lab0"
                className="w-full h-full object-contain rounded-lg"
              />
            </div>
            <div className="md:w-[60%] bg-White p-7 rounded-lg">
              <h1 className=" text-xl font-semibold text-secondaryColor uppercase mb-6">
                {labDetail?.labName}
              </h1>
              <div className="text-left lg:w-full lg:flex flex-col lg:justify-between">
                <div className="flex mb-4">
                  <p className="text-base font-medium text-textGray flex justify-between w-[25%]">
                    About
                    <span className="mr-5 font-normal h-5 text-sm leading-5  text-black not-italic w-1">
                      :
                    </span>
                  </p>
                  <p className="text-sm text-textGray font-light w-full">
                    {labDetail?.description}
                  </p>
                </div>
                <div className="flex mb-4">
                  <p className="text-base font-medium text-textGray flex justify-between w-[25%]">
                    Location
                    <span className="mr-5 font-normal h-5 text-sm leading-5  text-black not-italic w-1">
                      :
                    </span>
                  </p>
                  <p className="text-sm text-textGray font-light w-full">
                    {" "}
                    {labDetail?.address1}{" "}
                    {labDetail?.address2 === "NULL" ? "" : labDetail?.address2}{" "}
                    {labDetail?.pinCode}
                    {","} {labDetail?.city}
                  </p>
                </div>
                <div className="flex mb-4">
                  <p className="text-base font-medium text-textGray flex justify-between w-[25%]">
                    Hours
                    <span className="mr-5 font-normal h-5 text-sm leading-5  text-black not-italic w-1">
                      :
                    </span>
                  </p>
                  <p className="text-sm text-textGray font-light w-full">
                    {moment(labDetail?.labStartTime, "h:mm:ss A").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(labDetail?.labEndTime, "h:mm:ss A").format(
                      "hh:mm A"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-7 p-7 bg-White rounded-lg">
            <p className="text-xl text-primaryColor font-medium text-center py-3">
              All Lab Tests Provided By {labDetail?.labName}
            </p>
            <p className="text-base font-light text-center text-textGray">
              Search and find the test that suits your needs
            </p>
            <div className="flex justify-center mt-10">
              <Search
                className="apptSearch md:w-[60%]"
                placeholder="Search Lab Test"
                onChange={handleChange}
              />
            </div>
            <div className="w-full mx-auto mt-10">
              {labSearched.map((item, idx) => (
                <div className="mb-5 w-full" key={idx}>
                  <Card>
                    <div className="hidden md:flex items-center gap-5">
                      <div className="bg-lightBlue w-[5rem] h-[5rem] rounded-full p-5">
                        <img
                          src={TestCapsule}
                          alt="test"
                          className="h-full w-full"
                        />
                      </div>
                      <div className="flex justify-between w-full">
                        <div className="ml-5 w-[75%]">
                          <h6 className="text-lg font-semibold text-primaryColor mb-1">
                            {item?.labTestName}
                          </h6>
                          <p className="text-textGray text-base font-light">
                            <span className="font-medium">
                              Test Display Name:
                            </span>{" "}
                            {item?.testDisplayName}
                          </p>
                        </div>
                        <div className="w-[25%] flex justify-between items-center gap-3">
                          <p className="font-bold text-xl text-secondaryColor ">
                            ₹ {item.amount}
                          </p>
                          <button
                            className={`${
                              drugIDsInCart.indexOf(item.labTestCode) === -1
                                ? "bg-primaryColor text-White"
                                : "text-secondaryColor bg-White border border-secondaryColor"
                            }  w-[150px] h-[45px] text-sm font-medium rounded cursor-pointer`}
                            onClick={(e) => addTocart(e, item, idx, 1)}
                            disabled={
                              drugIDsInCart.indexOf(item.labTestCode) !== -1
                            }
                          >
                            {drugIDsInCart.indexOf(item.labTestCode) === -1
                              ? "Add to Cart"
                              : "ADDED"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={(e) => handleNavigation(e, item, cartaddedlab)}
                      //onClick={(e) => handleNavigation(e, item)}
                      className="text-secondaryColor underline text-sm cursor-pointer text-right"
                    >
                      View More Details
                    </div>
                    <div className="block md:hidden">
                      <div className="flex items-center mb-3 gap-2">
                        <div className="bg-lightBlue w-[2rem] h-[2rem] rounded-full p-2">
                          <img
                            src={TestCapsule}
                            alt="test"
                            className="h-full w-full"
                          />
                        </div>
                        <div className="ml-2">
                          <h6 className="text-sm font-semibold text-primaryColor mb-1">
                            {item?.labTestName}
                          </h6>
                        </div>
                      </div>
                      <p className="text-textGray text-xs font-light">
                        <span className="font-medium">Test Display Name:</span>{" "}
                        {item?.testDisplayName}
                      </p>
                    </div>
                    <hr className="border-lightBlue border-[1px] my-3" />
                    <div className="md:flex justify-between gap-5">
                      <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-13">
                        <span className="font-medium">Test Type :</span>{" "}
                        {item?.testType}
                      </p>
                      <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                        <span className="font-medium">Lab Name :</span>{" "}
                        {item?.labName}
                      </p>
                      <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                        <span className="font-medium">City :</span> {item.city}
                      </p>
                      <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                        <span className="font-medium">Address :</span>{" "}
                        {item?.address1}
                      </p>
                    </div>
                    <div className="flex justify-between items-center mt-5 md:hidden">
                      <p className="font-bold text-lg text-secondaryColor ">
                        ₹ {item?.amount}
                      </p>
                      <button
                        className={`${
                          drugIDsInCart.indexOf(item.labTestCode) === -1
                            ? "bg-primaryColor text-White"
                            : "text-secondaryColor bg-White border border-secondaryColor"
                        }  w-[150px] h-[45px]  text-sm font-medium rounded cursor-pointer `}
                        onClick={(e) => addTocart(e, item, idx, 1)}
                      >
                        {drugIDsInCart.indexOf(item?.labTestCode) === -1
                          ? "Add to Cart"
                          : "ADDED"}
                      </button>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default DiagnosticDetails;
