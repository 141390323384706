import React, { useEffect, useState, useRef } from "react";
import { Spin } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Click from "../../../Assets/Images/click.svg";
import Header from "../../Shared/Header";
import { secureStorage } from "../../../Redux/Reducer/authReducer";

const VideoCaptureComponent = ({ formData }) => {
  let logibOhj = localStorage.getItem("verifyLogOtpResult");
  console.log(logibOhj, "LoginObject");
  const storedData = JSON.parse(localStorage.getItem("verifyLogOtpResult"));
  const { firstName, lastName, dob, gender } = storedData?.patient || {};
  const [countdown, setCountdown] = useState(5);
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [hasCameraAccess, setHasCameraAccess] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState("unknown");
  const videoRef = useRef(null);
  const [isRecordingCounter, setIsRecordingCounter] = useState(30);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const history = useHistory();
  let s_token = secureStorage.getItem("token");

  const [isPreparingDataLoading, setIsPreparingDataLoading] = useState(false);

 
  useEffect(() => {
    console.log("Form Data:", formData); 
  }, [formData]);
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const permissionResult = await navigator.permissions.query({
          name: "camera",
        });
        setPermissionStatus(permissionResult.state);

        if (permissionResult.state === "granted") {
          try {
            setHasCameraAccess(true);
            requestCameraPermission();
          } catch (error) {
            console.error("Error accessing camera:", error);
            setHasCameraAccess(false);
          }
        } else if (permissionResult.state === "denied") {
          setHasCameraAccess(false);
        } else if (permissionResult.state === "prompt") {
          requestCameraPermission();
        }

        permissionResult.onchange = () => {
          setPermissionStatus(permissionResult.state);
          if (permissionResult.state === "granted") {
            setHasCameraAccess(true);
            requestCameraPermission();
          } else {
            setHasCameraAccess(false);
          }
        };
      } catch (error) {
        requestCameraPermission();
        console.error(
          "Permission API or getUserMedia is not supported:",
          error
        );
      }
    };

    checkCameraPermission();
  }, []);

  useEffect(() => {
    if (videoRef.current && mediaStream.current) {
      videoRef.current.srcObject = mediaStream.current;
      videoRef.current.play();
    }
  }, [videoRef.current, mediaStream.current]);

  useEffect(() => {
    let timerId;
    if (countdown <= 0) {
      startRecording(mediaStream.current);
      return;
    }
    if (mediaStream?.current) {
      timerId = setInterval(() => {
        setCountdown((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [countdown, mediaStream?.current]);

  async function requestCameraPermission() {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      mediaStream.current = userStream;
      setStream(userStream);

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream.current;
        videoRef.current.play();
      }
      setHasCameraAccess(true);
     
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  }

  const startRecording = (stream) => {
    setIsRecording(true);
    mediaRecorder.current = new MediaRecorder(stream);

    let chunks = [];

    mediaRecorder.current.ondataavailable = (e) => {
      chunks.push(e.data);
    };

    mediaRecorder.current.onstop = async () => {
      const blob = new Blob(chunks, { type: "video/webm" });
      const videoSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
      console.log(`Video size: ${videoSizeMB} MB`);
      setVideoUrl(URL.createObjectURL(blob));

    
      // await uploadVideoDirectly(blob);
      handleSubmit(blob);
    };

    mediaRecorder.current.start();
  };

  const uploadVideoDirectly = async (blob) => {
    try {
      const formData = new FormData();
      formData.append("file", blob, "recording.webm");

      console.log("Starting upload");

      const response = await axios.post(
        "https://caresathi.curebay.in/all_in_one/upload_file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${s_token}`,
          },
        }
      );

      console.log("Upload response:", response.data);

      const videoBase64 = response?.data?.base64;
      if (videoBase64) {
        console.log("Base64 video received, calling handleSubmit");
        // handleSubmit(videoBase64);
      } else {
        console.error("Error: Base64 video not found in response.");
      }
    } catch (error) {
      console.error("Error uploading video directly:", error);
    }
  };

  useEffect(() => {
    let recordingInterval;
    if (isRecordingCounter <= 0) {
      mediaRecorder.current.stop();
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      return;
    }
    if (mediaRecorder?.current?.state === "recording") {
      recordingInterval = window.setInterval(() => {
        setIsRecordingCounter((prevValue) => prevValue - 1);
      }, 1000);
    }
    return () => clearInterval(recordingInterval);
  }, [isRecordingCounter, mediaRecorder?.current?.state]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleSubmit = async (blob) => {
    try {
     
      const height = parseInt(formData?.height) || 0;
      const heightUnit = formData?.heightUnit || "cm"; 
      const weight = parseInt(formData?.weight) || 0; 
      const weightUnit = formData?.weightUnit || "kg"; 
  
      const payload = {
        name: `${firstName} ${lastName}`,
        age: calculateAge(dob),
        gender: gender,
        height,
        height_unit: heightUnit,
        weight,
        weight_unit: weightUnit,
        smoke: formData?.smoke === "true",
        diabetes: formData?.diabetes === "true",
      };
  
      const formDataPayload = new FormData();
      formDataPayload.append("video", blob, "recording.webm");
      formDataPayload.append("name", payload.name);
      formDataPayload.append("age", payload.age);
      formDataPayload.append("gender", payload.gender);
      formDataPayload.append("height", payload.height);
      formDataPayload.append("height_unit", payload.height_unit);
      formDataPayload.append("weight", payload.weight);
      formDataPayload.append("weight_unit", payload.weight_unit);
      formDataPayload.append("smoke", payload.smoke);
      formDataPayload.append("diabetes", payload.diabetes);
  
      let response = await axios.post(
        "https://contactlessvitals-stage.curebay.in/process-video/",
        formDataPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${s_token}`,
          },
        }
      );
  
      setIsPreparingDataLoading(false);
  
      if (response.status === 200) {
        // Process response as before
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err);
      setIsPreparingDataLoading(false);
    }
  };
  
  //   console.log(mediaRecorder?.current?.state ,"statattaacs")

  console.log(permissionStatus, "dsvoisdhiodhsuvdsvds");

  if (
    permissionStatus == "denied" ||
    (permissionStatus == "prompt" && !hasCameraAccess)
  ) {
    return (
      <div
        className="flex items-center justify-centerpx-4"
        style={{ height: "90vh" }}
      >
        <p className="text-center">
          Camera access is denied. Please enable camera access in your browser
          settings.
        </p>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div style={{ textAlign: "center", padding: "20px" }}>
        {!isRecording && countdown > 0 ? (
          <div className="flex items-center justify-center h-screen bg-white">
            <div className="text-center flex flex-col justify-center items-center">
              <div className="bg-[#f3f8ff] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={Click} alt="Finger Icon" className="w-12 h-12" />
              </div>
              <h2 className="text-lg font-semibold text-primaryColor mb-2">
                Please look straight into the camera
              </h2>
              <div className="flex items-center justify-center space-x-2">
                <div className="w-4 h-4 border-2 border-[#004171] rounded-full animate-pulse"></div>
                <p className="text-sm text-textGray">
                  Scanning starting in{" "}
                  <span className="text-secondaryColor font-semibold">
                    {countdown} sec
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {isRecordingCounter > 0 ? (
              <>
                <div className="flex flex-col items-center justify-center h-screen">
                  <div className="circular-scan relative w-[300px] h-[300px] rounded-full overflow-hidden">
                    <video
                      ref={videoRef}
                      autoPlay
                      playsInline
                      className="w-full h-full object-cover"
                    ></video>
                    <img
                      src="/path-to-heart-icon.svg"
                      alt="Heart Icon"
                      className="heart-icon absolute top-4 right-4 w-8 h-8"
                    />
                  </div>

                  <div className="mt-4 text-center">
                    <div className="progress-percentage text-4xl font-bold text-secondaryColor mt-4">
                      {`${Math.round(((30 - isRecordingCounter) / 30) * 100)}%`}
                    </div>
                    <p className="scan-message text-textGray text-lg mt-2">
                      Analyzing your result, please hold still for a few more
                      seconds
                    </p>
                    <p className="text-secondaryColor font-semibold mt-2">
                      Scanning completes in {isRecordingCounter} seconds
                    </p>
                  </div>
                </div>
              </>
            ) : null}
            {isPreparingDataLoading && (
              <div className="flex items-center justify-center h-screen">
                <p>Please wait preparing your vitals report</p> &nbsp; &nbsp;{" "}
                <Spin />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VideoCaptureComponent;
